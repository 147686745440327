import React from 'react';
import './App.scss';
import './global.scss';

import { SidebarComponent } from "./components/sidebar";
import { ContactComponent } from './components/contact';
import { LoginComponent } from './components/spotify';
import { Landscape } from "./components/landscape";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { faLink } from '@fortawesome/free-solid-svg-icons';

import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";

import { createPongGameData, fetchUser } from './services/get';
import { ThreeScene } from './components/three';
import { ToggleSlider } from 'react-toggle-slider';

// Import Parse minified version
import * as Parse from 'parse';
import { Pong } from './components/pong';
import { Test } from './components/test';
import { Homepage } from './components/homepage';

// Your Parse initialization configuration goes here
// this is used as parse in the get.service
// const PARSE_APPLICATION_ID = '0IY683DK0I6NUQYZo9ECGazyVvMy45kN1ONCgWVN';
// const PARSE_HOST_URL = process.env.NODE_ENV === 'development' ? 'https://parseapi.back4app.com' : 'https://jbrattke.com';
// const PARSE_JAVASCRIPT_KEY = 'mop5v9FaZGSteUgc7uYDpaIgiOpPFf9e6DubFOQa';
// Parse.initialize(PARSE_APPLICATION_ID, PARSE_JAVASCRIPT_KEY);
// (Parse as any).serverURL = PARSE_HOST_URL;

export default class App extends React.Component<{}, {
  testForGet: string,
  isVisible: boolean,
  is3D: boolean
}> {

  state = {
    testForGet: '',
    isVisible: false,
    is3D: true
  }

  testGet = async (): Promise<void> => {
    // try {
    //   let url = process.env.NODE_ENV === 'development' ? 'http://localhost:8050/api/ping' : 'https://jbrattke.com/api/ping';
    //   const res = await fetchUser();
    //   console.log(res);
    //   this.setState({testForGet: res?.toJSON().message});
    // } catch (e: any) {
    //   console.log(e)
    // }
    
    // const res = await fetchUser();
    // console.log(res);

    // const fileData = JSON.stringify({ test: 'test' });
    // const blob = new Blob([fileData], { type: "text/plain" });
    // const url = URL.createObjectURL(blob);

    // const res = await createPongGameData();

    const data = await fetch(process.env.REACT_APP_API_URL!, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(data => data.json())
    console.log(data);
  }

  render() {
    return (
      <div className="App">
        <BrowserRouter>
          <SidebarComponent isVisible={this.state.isVisible}></SidebarComponent>
          <div className="content" onClick={() => this.setState({isVisible: false})}>
            <Routes>
              <Route path="/" element={
                <div onClick={() => this.testGet()}>
                  <Homepage/>
                </div>
              }></Route>

              {/* OLD ROUTES */}
              <Route path='/oldhomepage' element={
                <header className="App-header" onClick={() => this.testGet()}>
                  <p>Jakob's Site</p>
                  <small>(In progress...)</small>
                  <small>{this.state.testForGet}</small>

                  <div className="d-flex" style={{position: 'absolute', top: 0, left: 0, padding: '10px', zIndex: 10}}>
                    <ToggleSlider
                      onToggle={(state) => {this.state.is3D = state;}}
                      transitionDuration={'300ms'}
                      barBackgroundColorActive={'#9368B7'}
                    />
                    <h4 style={{margin: '0 5px'}}>3D</h4>
                  </div>

                  <span style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', zIndex:9}}>
                    <span style={{padding: '10px'}}>
                      <ToggleSlider
                        onToggle={(state) => {this.state.is3D = state;}}
                        transitionDuration={'300ms'}
                        barBackgroundColorActive={'#9368B7'}
                      />
                      <h4 style={{margin: '0 5px'}}>3D</h4>
                    </span>
                    { this.state.is3D ? <ThreeScene /> : <Landscape /> }
                  </span>

                  <Outlet />
                </header>
              }>
                <Route path='projects' element={
                  <header className="App-header">
                    <p>Projects</p>
                    <div className="d-flex">
                      <small style={{fontSize: '0.5em'}}>In the works! A few of my previous projects can be found on  
                      </small>
                      <a href="https://github.com/jbrattke" target="_blank" rel="noreferrer" className="link" style={{marginLeft: '5px'}}>
                        github <FontAwesomeIcon icon={faLink}/>
                      </a>
                    </div>
                  </header>
                }/>
                <Route path='about' element={
                  <header className="App-header">
                    <p>About Me</p>
                  </header>
                }/>
                <Route path='contact' element={
                  <ContactComponent />
                }/>
              </Route>

              {/* RANDOM ROUTES */}
              <Route path='/test' element={
                <Test/>
              }/>
              {/* <Route path='/spotify' element={
                <LoginComponent />
              }></Route>
              <Route path='/pong' element={
                <Pong />
              }></Route> */}
            </Routes>
            
            {/* <div className="d-flex" style={{position: 'absolute', top: 0, left: 0, padding: '10px', zIndex: 10}}>
              <ToggleSlider
                onToggle={(state) => {this.state.is3D = state;}}
                transitionDuration={'300ms'}
                barBackgroundColorActive={'#9368B7'}
              />
              <h4 style={{margin: '0 5px'}}>3D</h4>
            </div>
            { this.state.is3D ? <ThreeScene /> : <Landscape /> } */}
          </div>
        </BrowserRouter>
  
        <div className='icons'>
          <button onClick={() => this.setState({isVisible: !this.state.isVisible})}>
            <FontAwesomeIcon icon={faBars} style={{fontSize: this.state.isVisible ? '0px' : ''}}/>
            <FontAwesomeIcon icon={faTimes} style={{fontSize: !this.state.isVisible ? '0px' : ''}}/>
          </button>
        </div>
  
      </div>
    );
  }
}