import React from "react";
import './landscape.scss';
import '../global.scss';
import Slider from '@mui/material/Slider';
import { ToggleSlider }  from "react-toggle-slider";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGear } from '@fortawesome/free-solid-svg-icons';
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import { faPause } from "@fortawesome/free-solid-svg-icons";

export interface Branch {
  length: number;
  angle: number;
  width: number;
  subBranches?: Branch[];
  leaves?: Leaf[];
}

export interface Leaf {
  size: number;
  x: number;
  y: number;
}

export interface Tree {
  mainBranches: Branch[];
  x: number;
  y: number;
  isBlank: boolean;
}

export interface Cloud {
  x: number;
  y: number;
  fluffs: Leaf[];
  speed: number;
}

export interface Moon {
  x: number;
  y: number;
  size: number;
}

let setupFlag = false;
let points: any[] = [];
let points2: any[] = [];
let trees: Tree[] = [];
let clouds: Cloud[] = [];
let moon: Moon = { x: 0, y: 0, size: 50 };

let scrollSpeed = 10;
let bezierT = 1;
let tickRate = 1000 / 30; // 30 fps
let showClouds = false;

let landscapeHex1 = "#271033";
let landscapeHex2 = "#3A405A";

let nightTopGradient = "#16142b";
let nightBottomGradient = '#9368B7';
let dayTopGradient = "#725AC1";
let dayBottomGradient = '#9368B7';

let activeItem: Cloud | Tree | null = null;
let mousePos = {x: 0, y: 0};

let dayCycleCounter = 0;
let dayCycleSpeed = 5;
let isDayFlag = true;

let timeoutID: NodeJS.Timer;

export interface State {
  showSettings: boolean;
  isRotating: boolean;
  isPaused: boolean;
}

let state : State = {
  showSettings: false,
  isRotating: false,
  isPaused: false
}

export class Landscape extends React.Component<{}, State> {

  constructor(props: any) {
    super(props);

    this.state = state;

    window.addEventListener('resize', this.resizeCanvas);
  }
  
  render() {
    return (
        <div style={{
              position: 'fixed',
              bottom: '-8px',
              width: '100%',
              height: 'calc(100% + 8px)',
              background: isDayFlag ? `linear-gradient(180deg, ${dayTopGradient} 0%, ${dayBottomGradient} 100%)` : `linear-gradient(180deg, ${nightTopGradient} 0%, ${nightBottomGradient} 100%)`,
            }}
            onMouseDown = {this.mouseHandler}
            onMouseUp = {this.mouseHandler}
            onMouseMove = {this.mouseMoveHandler}
        >
            <div style={{width: this.state.showSettings ? '300px' : '90px', height: this.state.showSettings ? '300px' : '50px',
              position: 'absolute', left: '0', bottom: '0', margin: '20px', transition: 'width 0.5s, height 0.5s, margin 0.5s', zIndex: 1000 }}
            >
              <div style={{position: 'absolute', left: '0', bottom: '0', margin: '9px', width: '64px', height: '32px', display: 'flex'}}>
                <FontAwesomeIcon 
                  className={"fs-2 gear" && this.state.isRotating ? "rotate" : ""}
                  style={{cursor: 'pointer', width: '32px', height: '32px', zIndex: 1000}} 
                  icon={faGear} 
                  onClick={() => {
                    this.setState({ showSettings: !this.state.showSettings });
                    this.setState({ isRotating: true });
                  }}
                  onAnimationEnd={() => this.setState({ isRotating: false })}
                />
                
                <FontAwesomeIcon 
                  style={{cursor: 'pointer', height: '32px', width: '32px', zIndex: 1000}}
                  className="ms-3 fs-2"
                  icon={ this.state.isPaused ? faPlay : faPause } 
                  onClick={() => {
                    this.setState({ isPaused: !this.state.isPaused });
                  }}
                />
              </div>
              <div
                style={{
                  height: '100%', width: '100%',
                  backgroundColor: 'grey', borderRadius: '10px', opacity: '0.3',
                  boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.75)', transition: 'width 0.5s, height 0.5s, margin 0.5s'
                }}
              ></div>
              <div
                style={{
                  height: '100%', width: '100%',
                  position: 'absolute', left: '0', bottom: '0', padding: '10px',
                }}
              >
                {
                  this.state.showSettings && 
                  (
                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', height: '100%'}}>
                      <div className="px-2 mb-2">
                        <p className="m-0 text-start" style={{color: 'white', fontFamily: 'philosopher, sans-serif'}}>Show Clouds</p>
                        <ToggleSlider
                          onToggle={(state) => {showClouds = state;}}
                          transitionDuration={'300ms'}
                          barBackgroundColorActive={'#9368B7'}
                        />
                      </div>
                      <div className="px-2">
                        <p className="m-0 text-start" style={{color: 'white', fontFamily: 'philosopher, sans-serif'}}>Day Cycle</p>
                        <Slider
                          defaultValue={dayCycleSpeed} 
                          onChange={handleChange3} 
                          min={0}
                          max={100}
                          key={dayCycleSpeed}
                          valueLabelDisplay="auto"
                          style={{color: 'white'}}
                        />
                      </div>
                      <div className="px-2">
                        <p className="m-0 text-start" style={{color: 'white', fontFamily: 'philosopher, sans-serif'}}>Scroll Speed</p>
                        <Slider
                          defaultValue={scrollSpeed} 
                          onChange={handleChange} 
                          min={0}
                          max={100}
                          key={scrollSpeed}
                          valueLabelDisplay="auto"
                          style={{color: 'white'}}
                        />
                      </div>
                      <div className="px-2">
                        <p className="m-0 text-start" style={{color: 'white', fontFamily: 'philosopher, sans-serif'}}>Landscape</p>
                        <Slider
                          defaultValue={10} 
                          onChange={handleChange2} 
                          min={-50}
                          max={100}
                          key={bezierT}
                          valueLabelDisplay="auto"
                          style={{color: 'white'}}
                        />
                      </div>
                    </div>
                  )
                }
                {
                  this.state.showSettings &&
                  (
                    <div style={{
                      position: 'absolute', right: '0', bottom: '0'
                    }}>
                      <p className="fs-7 mx-3 my-1" style={{overflow: "hidden", maxHeight: '48px'}}>
                        Click (and hold) on <br/> clouds/trees to interact
                      </p>
                    </div>
                  )
                }
              </div>
              
            </div>
            <canvas id="landscape" style={{
                width: '100%',
                height: '100%',
                zIndex: 1,
            }}></canvas>
        </div>
    );
  }

  resizeCanvas() {
    var canvas = document.getElementById("landscape") as HTMLCanvasElement;
    var ctx = canvas.getContext("2d");
    // canvas.width = window.innerWidth;
    // canvas.height = window.innerHeight;
    // canvas.style.width = "100%";
    // canvas.style.height = "100%";
    const dpi = window.devicePixelRatio;
    if (ctx) {
      ctx.scale(dpi, dpi);
    }

  }

  // After the component did mount, we set the state each second.
  componentDidMount() {
    var canvas = document.getElementById("landscape") as HTMLCanvasElement;
    var ctx = canvas.getContext("2d");
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;
    canvas.style.width = "100%";
    canvas.style.height = "100%";
    const dpi = window.devicePixelRatio;
    if (ctx) {
      ctx.scale(dpi, dpi);
    }

    if (!setupFlag) {
      this.setUpLandscape();
      setupFlag = true;
    } else if (ctx) {
      this.update(ctx, canvas);
    }
      
    timeoutID = setInterval(() => this.tick(), tickRate);
  }

  componentWillUnmount() {
    clearInterval(timeoutID);
    state = this.state;
  }

  tick() {
    if (!this.state.isPaused) {
      var canvas = document.getElementById("landscape") as HTMLCanvasElement;
      var ctx = canvas?.getContext("2d");
      if (ctx) {
        this.update(ctx, canvas);
      }
    }
  }

  update(ctx: CanvasRenderingContext2D, canvas: HTMLCanvasElement) {
    ctx.clearRect(0, 0, canvas.width, canvas.height);
  
    this.updateBackground();
    this.updateLandscape();
    this.updateTrees();

    if (showClouds) {
      this.updateClouds();
    }
  }

  updateBackground() {
    var canvas = document.getElementById("landscape") as HTMLCanvasElement;
    var ctx = canvas?.getContext("2d");
    if (ctx) {
      let grd = ctx.createRadialGradient(moon.x, moon.y, 0, moon.x, moon.y, canvas.width);
      if (!isDayFlag) {
        grd.addColorStop(0, nightTopGradient);
        grd.addColorStop(1, nightBottomGradient);
      } else {
        grd.addColorStop(0, dayTopGradient);
        grd.addColorStop(1, dayBottomGradient);
      }
      ctx.fillStyle = grd;
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      this.updateMoon();
    }
  }

  updateMoon() {
    var canvas = document.getElementById("landscape") as HTMLCanvasElement;
    var ctx = canvas.getContext("2d");
    if (ctx) {
      let startPoint = {
        x: 0,
        y: canvas.height
      };
      let controlPoint = {
        x: canvas.width / 2,
        y: -canvas.height / 2
      };
      let endPoint = {
        x: canvas.width,
        y: canvas.height
      }

      let point = this.getQuadraticCurvePoint(startPoint.x, startPoint.y, controlPoint.x, controlPoint.y, endPoint.x, endPoint.y, dayCycleCounter);
      moon.x = point.x;
      moon.y = point.y;

      if (!isDayFlag) {
        this.drawMoon(ctx, canvas);
      } else {
        this.drawSun(ctx, canvas);
      }

      dayCycleCounter += (dayCycleSpeed / 10000);

      if (dayCycleCounter > 1.3) {
        isDayFlag = !isDayFlag;
        dayCycleCounter = -0.3;
      }
    }
  }

  updateLandscape() {
    var canvas = document.getElementById("landscape") as HTMLCanvasElement;
    var ctx = canvas.getContext("2d");
    
    if (ctx) {
      let ceiling = canvas.height - (canvas.height / 4);
      
      for (let i = 0; i < points.length; i++) {
        points[i].x -= (scrollSpeed / 8);
        if (points[i].x < -((canvas.width / 5) * 2)) {
          points.splice(i, 1);
        }
      }
      if (points[points.length - 1].x < (canvas.width + (canvas.width / 5))) {
        points.push({
          x: canvas.width + ((canvas.width / 5) * 2),
          y: Math.floor(Math.random() * ((canvas.height - 20) - ceiling) + ceiling)
        });
      }

      for (let i = 0; i < points2.length; i++) {
        points2[i].x -= (scrollSpeed / 8);
        if (points2[i].x < -((canvas.width / 5) * 2)) {
          points2.splice(i, 1);
        }
      }
      if (points2[points2.length - 1].x < (canvas.width + (canvas.width / 5))) {
        points2.push({
          x: canvas.width + ((canvas.width / 5) * 2),
          y: Math.floor(Math.random() * ((canvas.height - 100) - ceiling) + ceiling)
        });
      }

      this.drawPoints(ctx, canvas, points2, landscapeHex2);
      this.drawPoints(ctx, canvas, points, landscapeHex1);
    }
  }

  setUpLandscape() {
    var canvas = document.getElementById("landscape") as HTMLCanvasElement;
    var ctx = canvas.getContext("2d");
    
    let ceiling = canvas.height - (canvas.height / 4);
    if (ctx) {
      // setting up first landscape
      points.push({
        x: canvas.width - ((canvas.width / 5)),
        y: canvas.height
      });
      points.push({
        x: canvas.width,
        y: Math.floor(Math.random() * ((canvas.height - 20) - ceiling) + ceiling)
      });
      points.push({
        x: canvas.width + (canvas.width / 5),
        y: Math.floor(Math.random() * ((canvas.height - 20) - ceiling) + ceiling)
      });

      // setting up second landscape
      points2.push({
        x: canvas.width,
        y: canvas.height
      });
      points2.push({
        x: canvas.width + (canvas.width / 5),
        y: Math.floor(Math.random() * ((canvas.height - 20) - ceiling) + ceiling)
      });

      this.setUpTrees();
      this.setUpClouds();
    }
  }

  setUpTrees() {
    var canvas = document.getElementById("landscape") as HTMLCanvasElement;
    var ctx = canvas.getContext("2d");
    if (ctx) {
      for (let i = 0; i < points2.length; i++) {
        if (i % 2 === 0) {
          trees.push(this.createTree(points2[i].x, points2[i].y));
        }
      }
    }
  }
 
  updateTrees() {
    var canvas = document.getElementById("landscape") as HTMLCanvasElement;
    var ctx = canvas.getContext("2d");

    if (ctx) {
      for (let i = 0; i < trees.length; i++) {
        if (trees[i] === activeItem) {
          // stick to mouse
          trees[i].x = mousePos.x;
          trees[i].y = mousePos.y;
        } else {
          trees[i].x -= scrollSpeed / 8;
          if (trees[i].x < -60) {
            trees.splice(i, 1);
          }
        }
      }

      for (let i = 0; i < points2.length; i++) {
        if (trees.length <= i) {
          if (Math.floor(Math.random() * 100) < 40) {
            trees.push(this.createTree(points2[i].x, points2[i].y, true));
          } else {
            let randYChange = Math.floor(Math.random() * 100);
            let randXChange = Math.floor(Math.random() * (100 + 100) - 100);
            trees.push(this.createTree(points2[i].x + randXChange, points2[i].y + randYChange));
          }
        }
      }
  
      this.drawTrees(ctx, canvas);
    }
  }

  setUpClouds() {
    var canvas = document.getElementById("landscape") as HTMLCanvasElement;
    var ctx = canvas.getContext("2d");
    if (ctx) {
      for (let i = 0; i < points2.length; i++) {
        if (i % 2 === 0) {
          clouds.push(this.createCloud(points2[i].x,  Math.floor(Math.random() * (250 - 50) + 50)));
        }
      }
    }
  }

  updateClouds() {
    var canvas = document.getElementById("landscape") as HTMLCanvasElement;
    var ctx = canvas.getContext("2d");

    if (ctx) {
      for (let i = 0; i < clouds.length; i++) {
        if (clouds[i] === activeItem) {
          // stick to mouse
          clouds[i].x = mousePos.x;
          clouds[i].y = mousePos.y;
        } else {
          clouds[i].x -= ((scrollSpeed / 8) + (scrollSpeed ? (clouds[i].speed / 8) : 0));
          if (clouds[i].x < -80) {
            clouds.splice(i, 1);
          }
        }
      }

      for (let i = 0; i < points2.length; i++) {
        if (clouds.length <= i) {
          let randYChange = Math.floor(Math.random() * (250 - 50) + 50);
          let randXChange = Math.floor(Math.random() * (100 + 100) - 100);
          clouds.push(this.createCloud(points2[i].x + randXChange, randYChange));
        }
      }
  
      this.drawClouds(ctx, canvas);
    }
  }

  mouseHandler(e: any) {
    // console.log(e, clouds);
    if (e.type === "mousedown" && e.target.id === "landscape") {
      for (let tree of trees) {
        for (let mainBranch of tree.mainBranches) {
          for (let subBranch of mainBranch.subBranches!) {
            if (subBranch.leaves) {
              for (let leaf of subBranch.leaves) {
                // if mouse is within leaf, use logic from drawTree to determine if mouse is within leaf
                if (((e.clientX > (tree.x - mainBranch.angle - subBranch.angle - leaf.x) && e.clientX < (tree.x - mainBranch.angle - subBranch.angle  - leaf.x) + leaf.size) 
                    || (e.clientX < (tree.x - mainBranch.angle - subBranch.angle - leaf.x) && e.clientX > (tree.x - mainBranch.angle - subBranch.angle - leaf.x) - leaf.size)) 
                  && ((e.clientY > (tree.y - mainBranch.length - subBranch.length - leaf.y) && e.clientY < (tree.y - mainBranch.length - subBranch.length - leaf.y) + leaf.size) 
                    || (e.clientY < (tree.y - mainBranch.length - subBranch.length - leaf.y) && e.clientY > (tree.y - mainBranch.length - subBranch.length - leaf.y) - leaf.size))) {
                  activeItem = tree;
                  break;
                }
              }
            }
          }
        }
      }

      for (let cloud of clouds) {
        for (let fluff of cloud.fluffs) {
          // if the clientX and clientY are within the fluff's x offset and y offset, set active cloud
          if (((e.clientX > fluff.x + cloud.x && e.clientX < fluff.x + cloud.x + fluff.size) || (e.clientX < fluff.x + cloud.x && e.clientX > fluff.x + cloud.x - fluff.size))
            && ((e.clientY > fluff.y + cloud.y && e.clientY < fluff.y + cloud.y + fluff.size) || (e.clientY < fluff.y + cloud.y && e.clientY > fluff.y + cloud.y - fluff.size))) {
            activeItem = cloud;
            break;
          }
        }
      }
    } else if (e.type === "mouseup") {
      if (activeItem) {
        activeItem = null;
      }
    }
  }

  mouseMoveHandler(e: any) {
    mousePos.x = e.clientX;
    mousePos.y = e.clientY;
  }

  drawPoints(ctx: CanvasRenderingContext2D, canvas: HTMLCanvasElement, posArr: any[] = [], color: string = '', yExtra: number = 0) {
    ctx.beginPath();
    ctx.moveTo(posArr[0].x, posArr[0].y);

    for (var i = 0; i < posArr.length - 1; i++) {
      var p0 = (i > 0) ? posArr[i - 1] : posArr[0];
      var p1 = posArr[i];
      var p2 = posArr[i + 1];
      var p3 = (i !== posArr.length - 2) ? posArr[i + 2] : p2;

      var cp1x = p1.x + (p2.x - p0.x) / 6 * bezierT;
      var cp1y = (p1.y + yExtra) + (p2.y - (p0.y + yExtra)) / 6 * bezierT;

      var cp2x = p2.x - (p3.x - p1.x) / 6 * bezierT;
      var cp2y = (p2.y + yExtra) - (p3.y - (p1.y + yExtra)) / 6 * bezierT;

      ctx.bezierCurveTo(cp1x, cp1y + yExtra, cp2x, cp2y + yExtra, p2.x, p2.y + yExtra);
    }
    ctx.lineTo(canvas.width, canvas.height);
    ctx.lineTo(0, canvas.height);
    ctx.lineTo(posArr[0].x, posArr[0].y);
    ctx.fillStyle = color ? color : "#4E3822";
    ctx.fill();
  }

  drawTrees(ctx: CanvasRenderingContext2D, canvas: HTMLCanvasElement) {
    for (let tree of trees) {
      this.drawTree(ctx, canvas, tree);
    }
  }

  drawClouds(ctx: CanvasRenderingContext2D, canvas: HTMLCanvasElement) {
    for (let cloud of clouds) {
      this.drawCloud(ctx, canvas, cloud);
    }
  }

  drawTree(ctx: CanvasRenderingContext2D, canvas: HTMLCanvasElement, tree: Tree) {
    if (!tree.isBlank) {
      for (let i = 0; i < tree.mainBranches.length; i++) {
        let mainBranchLength = tree.mainBranches[i].length;
        let mainBranchAngle = tree.mainBranches[i].angle;
        let mainBranchWidth = tree.mainBranches[i].width;
  
        ctx.beginPath();
        ctx.moveTo(tree.x, tree.y);
        ctx.lineTo(tree.x - mainBranchAngle, tree.y - mainBranchLength);
        ctx.lineWidth = mainBranchWidth;
        ctx.strokeStyle = '#472D30';
        ctx.stroke();
  
        for (let j = 0; j < tree.mainBranches[i].subBranches!.length; j++) {
          let subBranchLength = tree.mainBranches[i].subBranches![j].length;
          let subBranchAngle = tree.mainBranches[i].subBranches![j].angle;
          let subBranchWidth = tree.mainBranches[i].subBranches![j].width;
  
          for (let k = 0; k < tree.mainBranches[i].subBranches![j].leaves!.length; k++) {
            let leafSize = tree.mainBranches[i].subBranches![j].leaves![k].size;
            let leafX = tree.mainBranches[i].subBranches![j].leaves![k].x;
            let leafY = tree.mainBranches[i].subBranches![j].leaves![k].y;
            
            ctx.beginPath();
            ctx.arc(tree.x - mainBranchAngle - subBranchAngle - leafX, tree.y - mainBranchLength - subBranchLength - leafY, leafSize, 0, 2 * Math.PI, false);

            let grd = ctx.createLinearGradient(tree.x - mainBranchAngle - subBranchAngle - leafX, tree.y - mainBranchLength - subBranchLength - leafY, tree.x - mainBranchAngle - subBranchAngle - leafX, tree.y - mainBranchLength - subBranchLength - leafY + leafSize);
            grd.addColorStop(0, '#BEA7E5');
            grd.addColorStop(1, '#a691c9');
            ctx.fillStyle = grd;
            ctx.fill();
          }
  
          ctx.beginPath();
          ctx.moveTo(tree.x - mainBranchAngle, tree.y - mainBranchLength);
          ctx.lineTo(tree.x - mainBranchAngle - subBranchAngle, tree.y - mainBranchLength - subBranchLength);
          ctx.lineWidth = subBranchWidth;
          ctx.strokeStyle = '#472D30';
          ctx.stroke();
        }
      }
    }
  }

  drawCloud(ctx: CanvasRenderingContext2D, canvas: HTMLCanvasElement, cloud: Cloud) {
    for (let segment of cloud.fluffs) {
      ctx.beginPath();
      ctx.moveTo(segment.x + cloud.x, segment.y + cloud.y);
      ctx.arc(segment.x + cloud.x, segment.y + cloud.y, segment.size, 0, 2 * Math.PI, false);

      ctx.fillStyle = '#FFFFFF';
      ctx.fill();

      // let grd = ctx.createLinearGradient(segment.x + cloud.x, segment.y + cloud.y, segment.x + cloud.x, segment.y + cloud.y + segment.size);
      // grd.addColorStop(0, isDayFlag ? dayBottomGradient : nightBottomGradient);
      // grd.addColorStop(1, '#FFFFFF');
      // ctx.fillStyle = grd;
      // ctx.fill();
    }
  }

  drawMoon(ctx: CanvasRenderingContext2D, canvas: HTMLCanvasElement) {
    ctx.beginPath();
    ctx.moveTo(moon.x, moon.y);
    ctx.arc(moon.x, moon.y, moon.size, 0, 2 * Math.PI, false);

    // ctx.fillStyle = '#4B4E6D';
    let grd = ctx.createLinearGradient(moon.x - moon.size, moon.y - moon.size, moon.x + moon.size, moon.y + moon.size);
    grd.addColorStop(0, nightTopGradient);
    grd.addColorStop(1, '#4B4E6D');
    ctx.fillStyle = grd;
    ctx.fill();

    // create five unique "craters" on the moon, each with a set size and position
    let craterHex = '#0a0930';
    ctx.fillStyle = craterHex;

    ctx.beginPath();
    ctx.moveTo(moon.x, moon.y);
    ctx.ellipse(moon.x - 20, moon.y - 15, 15, 25, Math.PI / 4, 0, 2 * Math.PI);
    ctx.fill();

    ctx.beginPath();
    ctx.moveTo(moon.x, moon.y);
    ctx.ellipse(moon.x + 25, moon.y - 15, 15, 10, Math.PI / 4, 0, 2 * Math.PI);
    ctx.fill();

    // ctx.beginPath();
    // ctx.moveTo(moon.x, moon.y);
    // ctx.arc(moon.x + 7, moon.y + 28, 18, 0, 2 * Math.PI, false);
    // ctx.fill();
  }

  drawSun(ctx: CanvasRenderingContext2D, canvas: HTMLCanvasElement) {
    ctx.beginPath();
    ctx.moveTo(moon.x, moon.y);
    ctx.arc(moon.x, moon.y, moon.size, 0, 2 * Math.PI, false);

    // ctx.fillStyle = '#4B4E6D';
    let grd = ctx.createLinearGradient(moon.x - moon.size, moon.y - moon.size, moon.x + moon.size, moon.y + moon.size);
    grd.addColorStop(0, dayTopGradient);
    grd.addColorStop(1, '#FECEF1');
    ctx.fillStyle = grd;
    ctx.fill();
  }

  createTree(x: number, y: number, isBlank: boolean = false): Tree {
    let tree = {} as Tree;
    tree.x = x;
    tree.y = y;
    tree.isBlank = isBlank;

    let mainBranchCount = Math.floor(Math.random() * (4 - 1) + 1);
    tree.mainBranches = [];
    for (let i = 0; i < mainBranchCount; i++) {
      let mainBranchLength = Math.floor(Math.random() * (100 - 50) + 50);
      let mainBranchAngle = Math.floor(Math.random() * (30 + 30) - 30);
      let mainBranchWidth = Math.floor(Math.random() * (10 - 5) + 5);

      let mainBranch = {} as Branch;
      mainBranch.length = mainBranchLength;
      mainBranch.angle = mainBranchAngle;
      mainBranch.width = mainBranchWidth;

      let subBranchCount = Math.floor(Math.random() * (4 - 2) + 2);
      mainBranch.subBranches = [];
      for (let j = 0; j < subBranchCount; j++) {
        let subBranchLength = Math.floor(Math.random() * (50 - 20) + 20);
        let subBranchAngle = Math.floor(Math.random() * (45 + 45) - 45);
        let subBranchWidth = Math.floor(Math.random() * (10 - 5) + 5);

        let subBranch = {} as Branch;
        subBranch.length = subBranchLength;
        subBranch.angle = subBranchAngle;
        subBranch.width = subBranchWidth;

        let leafCount = Math.floor(Math.random() * (3 - 1) + 1);
        subBranch.leaves = [];
        for (let k = 0; k < leafCount; k++) {
          let leafSize = Math.floor(Math.random() * (40 - 15) + 15);
          let leafX = Math.floor(Math.random() * (10 - 5) + 5);
          let leafY = Math.floor(Math.random() * (10 - 5) + 5);

          let leaf = {} as Leaf;
          leaf.size = leafSize;
          leaf.x = leafX;
          leaf.y = leafY;

          subBranch.leaves.push(leaf);
        }

        mainBranch.subBranches.push(subBranch);
      }

      tree.mainBranches.push(mainBranch);
    }
    return tree;
  }

  createCloud(x: number, y: number): Cloud {
    let cloud = {} as Cloud;
    cloud.x = x;
    cloud.y = y;
    cloud.speed = Math.floor(Math.random() * (8 + 4) - 4);

    // Create cloud shape
    let cloudShapeCount = Math.floor(Math.random() * (7 - 4) + 4);
    cloud.fluffs = [];
    for (let i = 0; i < cloudShapeCount; i++) {
      let fluffSize = Math.floor(Math.random() * (60 - 20) + 20);
      let fluffX = Math.floor(Math.random() * (60 + 60) - 60);
      let fluffY = Math.floor(Math.random() * (60 + 60) - 60);

      let fluff = {} as Leaf;
      fluff.size = fluffSize;
      fluff.x = fluffX;
      fluff.y = fluffY;

      cloud.fluffs.push(fluff);
    }
    return cloud;
  }

  _getQBezierValue(t: number, p1: number, p2: number, p3: number) {
    var iT = 1 - t;
    return iT * iT * p1 + 2 * iT * t * p2 + t * t * p3;
  }

  getQuadraticCurvePoint(startX: any, startY: any, cpX: any, cpY: any, endX: any, endY: any, position: any) {
    return {
        x:  this._getQBezierValue(position, startX, cpX, endX),
        y:  this._getQBezierValue(position, startY, cpY, endY)
    };
  }
}

const handleChange = (event: Event, newValue: number | number[]) => {
  scrollSpeed = newValue as number;
};

const handleChange2 = (event: Event, newValue: number | number[]) => {
  bezierT = newValue as number / 10;
};

const handleChange3 = (event: Event, newValue: number | number[]) => {
  dayCycleSpeed = newValue as number;
};