import React from "react";
import './three.scss';
import '../global.scss';
import './test.scss';
import * as THREE from 'three';
import image from '../assets/imgs/moon2.jpg';

interface State {}

export class Test extends React.Component<{}, State> {
    world!: World;

    constructor(props: {}) {
        super(props);
    }

    render() {
        return (
            <div style={{
                width: '100%',
                height: '100%',
                position: 'absolute',
                top: 0,
                left: 0,
                backgroundColor: 'black',
                overflow: 'hidden',
            }}>
                <div id="homepage">
                    <img src={ image } style={{
                        height: '100%',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                    }} alt=""/>
                    <div className="glitch" data-text="Jakob Brattke">Jakob Brattke</div>
                    <p style={{color: 'white'}}>- Web Dev, </p>
                </div>
                {/* <img src={ image } alt="alt" style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    zIndex: 0,
                }}/> */}
            </div>
        )
    }

    // After the component did mount, we set the state each second.
    async componentDidMount() {
        // Get a reference to the container element
        const container = document.getElementById('homepage')!;
    
        // 1. Create an instance of the World app
        this.world = new World(container);

        // complete async tasks
        await this.world.init()
        .catch((e) => {
            console.log(e)
        }).then(() => {
            // 2. Render the scene
            this.world.start();
        });
    }

    componentWillUnmount(): void {
        this.world.stop();
        const container = document.getElementById('homepage');
        if (container && container.childNodes.length > 0) {
            // container.removeChild(container.childNodes[0]);
            for (let i = 0; i < container.childNodes.length; i++) {
                if (container.childNodes[i].nodeName === 'CANVAS') {
                    container.removeChild(container.childNodes[i]);
                }
            }
        }
    }
}

interface Mouse {
    x: number;
    y: number;
    prevX: number;
    prevY: number;
    vX: number;
    vY: number;
}

class World {
    camera: THREE.OrthographicCamera;
    scene: THREE.Scene;
    renderer: THREE.WebGLRenderer;
    loop: Loop;
    image: HTMLImageElement;
    texture!: THREE.DataTexture;
    material!: THREE.ShaderMaterial;
    
    size: number = 34;
    
    mouse: Mouse = {
        x: 0,
        y: 0,
        prevX: 0,
        prevY: 0,
        vX: 0,
        vY: 0,
    };
    time = 0;

    // 1. Create an instance of the World app
    constructor(container: HTMLElement) {
        this.scene = new THREE.Scene();

        // this.camera = new THREE.PerspectiveCamera(
        //     70,
        //     window.innerWidth / window.innerHeight,
        //     0.1,
        //     100
        // );
        var frustumSize = 1;
        this.camera = new THREE.OrthographicCamera(frustumSize / -2, frustumSize / 2, frustumSize / 2, frustumSize / -2, -1000, 1000);
        this.camera.position.set(0, 0, 2);

        this.image = container.querySelector('img')!;
        this.renderer = new THREE.WebGLRenderer({});
        this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
        this.renderer.setSize(this.image.width, this.image.height);
        this.renderer.setClearColor(0xeeeeee, 1);
        this.renderer.physicallyCorrectLights = true;

        this.loop = new Loop(this.camera, this.scene, this.renderer);
        
        this.mouseEvents();
        this.addObjects();

        container.appendChild(this.renderer.domElement);
        new Resizer(container, this.camera, this.renderer);
        window.addEventListener('resize', () => {new Resizer(container, this.camera, this.renderer)});
    }

    async init() {
        
    }
    
    render() {
        this.renderer.render(this.scene, this.camera);
    }

    start() {
        this.loop.start();
    }
    
    stop() {
        this.loop.stop();
    }

    addObjects() {
        this.regenerateGrid();

        let texture = new THREE.Texture(this.image);
        // texture.needsUpdate = true;
        this.material = new THREE.ShaderMaterial({
            // extensions: {
            //     derivatives: "#extension GL_OES_standard_derivatives : enable"
            // },
            side: THREE.DoubleSide,
            uniforms: {
                time: {
                    value: 0
                },
                resolution: {
                    value: new THREE.Vector4()
                },
                uTexture: {
                    value: texture
                },
                uDataTexture: {
                    value: this.texture
                },
            },
            vertexShader: `
                uniform float time;
                varying vec2 vUv;
                varying vec3 vPosition;
                uniform vec2 pixels;
                float PI = 3.141592653589793238;
                void main() {
                vUv = uv;
                gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
                }
            `,
            fragmentShader: `
                uniform float time;
                uniform float progress;
                uniform sampler2D uDataTexture;
                uniform sampler2D uTexture;

                uniform vec4 resolution;
                varying vec2 vUv;
                varying vec3 vPosition;
                float PI = 3.141592653589793238;
                void main()	{
                    vec2 newUV = (vUv - vec2(0.5))*resolution.zw + vec2(0.5);
                    vec4 color = texture2D(uTexture,newUV);
                    vec4 offset = texture2D(uDataTexture,vUv);
                    gl_FragColor = vec4(vUv,0.0,1.);
                    gl_FragColor = vec4(offset.r,0.,0.,1.);
                    gl_FragColor = color;
                    gl_FragColor = texture2D(uTexture,newUV - 0.02*offset.rg);
                    // gl_FragColor = offset;
                }
            `
        });

        let geometry = new THREE.PlaneGeometry(1, 1, 1, 1);

        let plane = new THREE.Mesh(geometry, this.material);

        (plane as any).tick = (delta: number) => {
            this.time += delta;
            this.updateTexture()
            this.material.uniforms.time.value = this.time;
        };

        this.scene.add(plane);
        this.loop.updatables.push(plane);
    }

    updateTexture() {
        let relaxation = 0.9;
        let mouse = 0.25;
        let strength = 1;

        let data = this.texture.image.data;
        for (let i = 0; i < data.length; i += 3) {
            data[i] *= relaxation
            data[i + 1] *= relaxation
        }

        let gridMouseX = this.size * this.mouse.x;
        let gridMouseY = this.size * (1 - this.mouse.y);
        let maxDist = this.size * mouse;
        let aspect = this.image.height / this.image.width;

        for (let i = 0; i < this.size; i++) {
            for (let j = 0; j < this.size; j++) {

                let distance = ((gridMouseX - i) ** 2) / aspect + (gridMouseY - j) ** 2
                let maxDistSq = maxDist ** 2;

                if (distance < maxDistSq) {

                    let index = 3 * (i + this.size * j);

                    let power = maxDist / Math.sqrt(distance);
                    power = clamp(power, 0, 10)
                    // if(distance <this.size/32) power = 1;
                    // power = 1;

                    data[index] += strength * 100 * this.mouse.vX * power;
                    data[index + 1] -= strength * 100 * this.mouse.vY * power;

                }
            }
        }

        this.mouse.vX *= 0.9;
        this.mouse.vY *= 0.9;
        this.texture.needsUpdate = true
    }

    regenerateGrid() {
        this.size = 15;

        const width = this.size;
        const height = this.size;
    
        const size = width * height;
        const data = new Float32Array(4 * size);
        const color = new THREE.Color(0xffffff);
    
        const r = Math.floor(color.r * 255);
        const g = Math.floor(color.g * 255);
        const b = Math.floor(color.b * 255);
    
        for (let i = 0; i < size; i++) {
            let r = Math.random() * 255 - 125;
            let r1 = Math.random() * 255 - 125;

            const stride = i * 4;

            data[stride] = r;
            data[stride + 1] = r1;
            data[stride + 2] = r;
            data[stride + 3] = 255;
    
        }
    
        // used the buffer to create a DataTexture
    
        this.texture = new THREE.DataTexture(data, width, height, THREE.RGBAFormat, THREE.FloatType);
    
        this.texture.magFilter = this.texture.minFilter = THREE.NearestFilter;
    
        if (this.material) {
          this.material.uniforms.uDataTexture.value = this.texture;
          this.material.uniforms.uDataTexture.value.needsUpdate = true;
        }
    }

    mouseEvents() {
        window.addEventListener('mousemove', (e) => {
          this.mouse.x = e.clientX / this.image.width;
          this.mouse.y = e.clientY / this.image.height;
    
          this.mouse.vX = this.mouse.x - this.mouse.prevX;
          this.mouse.vY = this.mouse.y - this.mouse.prevY;

          this.mouse.prevX = this.mouse.x
          this.mouse.prevY = this.mouse.y;
        })
    }
}

class Loop {
    camera: THREE.OrthographicCamera;
    scene: THREE.Scene;
    renderer: THREE.WebGLRenderer;
    updatables: any[];
    private clock: THREE.Clock = new THREE.Clock();
    
    constructor(camera: THREE.OrthographicCamera, scene: THREE.Scene, renderer: THREE.WebGLRenderer) {
      this.camera = camera;
      this.scene = scene;
      this.renderer = renderer;
      this.updatables = [];
    }
  
    start() {
        this.renderer.setAnimationLoop(() => {
            // tell every animated object to tick forward one frame
            this.tick();

            // render a frame
            this.renderer.render(this.scene, this.camera);
        });
    }
  
    stop() {
        this.renderer.setAnimationLoop(null);
    }

    // Code to update animations will go here
    tick() {
        const delta = this.clock.getDelta();

        for (const object of this.updatables) {
            object.tick(delta);
        }
    }
}

class Resizer {
    constructor(container: HTMLElement, camera: THREE.OrthographicCamera, renderer: THREE.WebGLRenderer) {
        // Set the camera's aspect ratio
        // camera.aspect = container.clientWidth / container.clientHeight;

        // update the camera's frustum
        camera.updateProjectionMatrix();
    
        // update the size of the renderer AND the canvas
        renderer.setSize(container.clientWidth, container.clientHeight);
    
        // set the pixel ratio (for mobile devices)
        renderer.setPixelRatio(window.devicePixelRatio);
    }
}

function clamp(number: number, min: number, max: number) {
    return Math.max(min, Math.min(number, max));
}