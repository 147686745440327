import './three.scss';
import '../global.scss';

import * as THREE from 'three';
import { AmbientLight, AnimationMixer, BoxGeometry, Camera, Clock, Color, ConeGeometry, DirectionalLight, Mesh, MeshBasicMaterial, MeshStandardMaterial, PerspectiveCamera, PlaneGeometry, Renderer, Scene, SphereGeometry, Vector3, WebGLRenderer } from "three";
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { MeshSurfaceSampler } from 'three/examples/jsm/math/MeshSurfaceSampler.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';

import { createNoise2D } from 'simplex-noise';

let world : World | undefined;

export function getWorldInstance(container: HTMLElement) {
    if (!world) {
        world = new World(container)
        // Object.freeze(world);
    }
    return world;
}

export class World {
    camera: THREE.PerspectiveCamera;
    scene: THREE.Scene;
    renderer: THREE.WebGLRenderer;
    loop: Loop;
    dayCycle: DayCycle;
    initialized: boolean = false;
    plane1Sampler?: MeshSurfaceSampler;

    // 1. Create an instance of the World app
    constructor(container: HTMLElement) {
        this.camera = createCamera();
        this.scene = createScene();
        this.renderer = createRenderer();
        this.loop = new Loop(this.camera, this.scene, this.renderer);

        this.dayCycle = new DayCycle(this.renderer.domElement, this.scene);

        // const cube = createCube();
        // const plane = createPlane();
        const planeManager = new PlaneManager(this.scene, this.loop);
        const plane2Manager = new PlaneManager(this.scene, this.loop, true);
        // const plane2 = createPlane2();
        const { light, ambientLight } = createLights();
        const controls = createControls(this.camera, this.renderer.domElement);
        
        // const samplePlane = new Mesh(plane.geometry.toNonIndexed(), plane.material);
        // this.plane1Sampler = new MeshSurfaceSampler(samplePlane).build();

        // this.loop.updatables.push(cube);
        this.loop.updatables.push(controls);
        this.loop.updatables.push(planeManager, plane2Manager);
        // this.loop.updatables.push(plane, plane2);
      
        // this.scene.add(cube);
        // this.scene.add(plane, plane2);
        this.scene.add(light, ambientLight);
        
        container.append(this.renderer.domElement);
        new Resizer(container, this.camera, this.renderer);
    }

    async init() {
        if (this.initialized) return;

        this.initialized = true;

        // asynchronous setup here
        const { moon, mixer } = await loadMoon();

        moon.scale.set(0.1, 0.1, 0.1);
        moon.position.setZ(-50);

        // moon tick
        (moon as any).tick = (delta: number) => {

            this.dayCycle.inc();

            let point = this.dayCycle.getPos();
            moon.position.set(point.x, point.y, moon.position.z);

            moon.rotateX(0.001);
            moon.rotateY(0.001);
            moon.rotateZ(0.001);
            
            mixer.update(delta);
        }
        
        this.loop.updatables.push(moon);
        this.scene.add(moon);
    }
    
    render() {
        this.renderer.render(this.scene, this.camera);
    }

    start(container?: HTMLElement) {
        if (container) {
            new Resizer(container, this.camera, this.renderer);
            container.append(this.renderer.domElement);
        }
        this.loop.start();
    }
    
    stop() {
        this.loop.stop();
    }
}

let nightTopGradient = "#16142b";
let nightBottomGradient = '#9368B7';
let dayTopGradient = "#725AC1";
let dayBottomGradient = '#9368B7';

class DayCycle {
    private _time: number = 0;
    private _dayLength: number = 1;
    private _dayCycleSpeed: number = 5 / 50000;
    private _isDay: boolean = true;
    private _container: HTMLCanvasElement;

    private startPoint: THREE.Vector3 = new THREE.Vector3(-50, 0, 0);
    private controlPoint: THREE.Vector3 = new THREE.Vector3(0, 40, 0);
    private endPoint: THREE.Vector3 = new THREE.Vector3(50, 0, 0);

    constructor(container: HTMLCanvasElement, scene?: THREE.Scene) {
        this._container = container;
    }

    inc() {
        this._time += this._dayCycleSpeed;
        if (this._time > this._dayLength) {
            this._time = 0;
        }

        // var ctx = this._container?.getContext("2d");
        // if (ctx) {
        //     let pos = this.getPos();
        //     let grd = ctx.createRadialGradient(pos.x, pos.y, 0, pos.x, pos.y, this._container.width);
        //     if (!this._isDay) {
        //         grd.addColorStop(0, nightTopGradient);
        //         grd.addColorStop(1, nightBottomGradient);
        //     } else {
        //         grd.addColorStop(0, dayTopGradient);
        //         grd.addColorStop(1, dayBottomGradient);
        //     }
        //     ctx.fillStyle = grd;
        //     ctx.fillRect(0, 0, this._container.width, this._container.height);
        // }
    }

    getPos() {
        let point = getQuadraticCurvePoint(this.startPoint.x, this.startPoint.y, this.controlPoint.x, this.controlPoint.y, this.endPoint.x, this.endPoint.y, this._time);
        return point;
    }
}

class Loop {
    camera: THREE.PerspectiveCamera;
    scene: THREE.Scene;
    renderer: THREE.WebGLRenderer;
    updatables: any[];
    private clock: THREE.Clock = new THREE.Clock();
    
    constructor(camera: THREE.PerspectiveCamera, scene: THREE.Scene, renderer: THREE.WebGLRenderer) {
      this.camera = camera;
      this.scene = scene;
      this.renderer = renderer;
      this.updatables = [];
    }
  
    start() {
        this.renderer.setAnimationLoop(() => {
            // tell every animated object to tick forward one frame
            this.tick();

            // render a frame
            this.renderer.render(this.scene, this.camera);
        });
    }
  
    stop() {
        this.renderer.setAnimationLoop(null);
    }

    // Code to update animations will go here
    tick() {
        const delta = this.clock.getDelta();

        for (const object of this.updatables) {
            object.tick(delta);
        }
    }
}

class Resizer {
    constructor(container: HTMLElement, camera: THREE.PerspectiveCamera, renderer: THREE.WebGLRenderer) {
        // Set the camera's aspect ratio
        camera.aspect = container.clientWidth / container.clientHeight;

        // update the camera's frustum
        camera.updateProjectionMatrix();
    
        // update the size of the renderer AND the canvas
        renderer.setSize(container.clientWidth, container.clientHeight);
    
        // set the pixel ratio (for mobile devices)
        renderer.setPixelRatio(window.devicePixelRatio);
    }
}

async function loadMoon() {
    const loader = new GLTFLoader();

    const downloadUrl = new URL('../assets/models/lowilds_planet.glb', import.meta.url);
    const moonData = await loader.loadAsync(downloadUrl.href);

    // (moonData.scene as any).tick = () => {
    //     moonData.scene.rotation.y += 0.001;
    // };

    const clip = moonData.animations[0];

    const mixer = new AnimationMixer(moonData.scene.children[0]);
    const action = mixer.clipAction(clip);
    action.play();

    const moon = moonData.scene.children[0];

    return { moon , mixer };
}

function createControls(camera: THREE.PerspectiveCamera, canvas: WebGLRenderer["domElement"]) {
    const controls = new OrbitControls(camera, canvas);

    controls.target.set(0, 0, 0);
    
    controls.enableDamping = true;
    controls.minPolarAngle = Math.PI / 3;
    controls.maxPolarAngle = Math.PI - Math.PI / 2;
    controls.minAzimuthAngle = -Math.PI / 4;
    controls.maxAzimuthAngle = Math.PI / 4;
    controls.enableZoom = false;

    (controls as any).tick = () => controls.update();

    return controls;
}

function createLights() {
    // Create a directional light
    const light = new DirectionalLight('white', 5);
    
    const ambientLight = new AmbientLight('white', 5);

    // move the light right, up, and towards us
    light.position.set(0, 10, 10);

    return {light, ambientLight};
}

function createRenderer() {
    const renderer = new WebGLRenderer({ antialias: true });

    // turn on the physically correct lighting model
    renderer.physicallyCorrectLights = true;
  
    return renderer;
}

function createScene() {
    const scene = new Scene();
  
    scene.background = new Color('#725AC1');
  
    return scene;
}

function createCamera() {
    const camera = new PerspectiveCamera(
      70, // fov = Field Of View
      1, // aspect ratio (dummy value)
      0.1, // near clipping plane
      100, // far clipping plane
    );
  
    // move the camera back so we can view the scene
    camera.position.set(0, 0, 10);
    camera.zoom = 1;
  
    return camera;
}

class PlaneManager {
    private _planes: THREE.Mesh[] = [];
    private _trees: THREE.Object3D<THREE.Event>[][] = [];
    private _scene: THREE.Scene;
    private _loop: Loop;
    private panSpeed = 2;
    private planeType = false;

    constructor(scene: THREE.Scene, loop: Loop, type = false) {
        this._scene = scene;
        this._loop = loop;
        this.planeType = type;
        
        this._loop.updatables.push(this);

        let plane = this.createPlane();
        plane.position.x = -40;
        let pos = plane.geometry.getAttribute("position");
        let pa = pos.array as any;

        let plane2 = this.createPlane(pa);
        plane2.position.x = 40;
        pos = plane2.geometry.getAttribute("position");
        pa = pos.array as any;

        let plane3 = this.createPlane(pa);
        plane3.position.x = 120;

        this.addPlane(plane);
        this.addPlane(plane2);
        this.addPlane(plane3);
    }

    // going to be called in the loop updateables
    tick(delta: number) {
        for (let plane of this._planes) {
            plane.position.x -= delta * this.panSpeed;
        }

        for (let trees of this._trees) {
            for (let tree of trees) {
                tree.position.x -= delta * this.panSpeed;
            }
        }

        let planeAdd = this._planes.find(plane => plane.position.x < -40);
        let planeDelete = this._planes.find(plane => plane.position.x < -120);
        if (planeAdd && this._planes.length < 3) {
            let pos = this._planes[this._planes.length - 1].geometry.getAttribute("position");
            let pa = pos.array as any;

            let plane = this.createPlane(pa);
            plane.position.x = 120;

            this.addPlane(plane);
        }
        if (planeDelete) {
            if (!this.planeType) {
                this._trees[this._planes.indexOf(planeDelete)].map(t => this._scene.remove(t));
                this._trees.slice(this._planes.indexOf(planeDelete), 1);
            }
            
            this._scene.remove(planeDelete);
            this._planes = this._planes.filter(p => p !== planeDelete);
        }
    }

    createPlane(pa?: any[]): THREE.Mesh {
        if (this.planeType) {
            return createPlane2(pa);
        }
        return createPlane(pa);
    }

    async addPlane(plane: THREE.Mesh) {
        this._planes.push(plane);
        this._scene.add(plane);

        plane.updateMatrixWorld();
        plane.updateMatrix();

        if (!this.planeType) {
            // Sample randomly from the surface, creating an instance of the sample geometry at each sample point.
            const samplePlane = new Mesh(plane.geometry.toNonIndexed(), plane.material);
            let sampler = new MeshSurfaceSampler(samplePlane).build();
            let trees: THREE.Object3D<THREE.Event>[] = [];
            for ( let i = 0; i < 10; i ++ ) {
    
                const tree = await loadTree();
    
                const _position = new THREE.Vector3();
                sampler.sample( _position );
    
                tree.scale.set(0.1, 0.1, 0.1);
    
                // translation applied to the plane, which for some reason the sampler doesn't take into account
                _position.applyAxisAngle(new Vector3(1, 0, 0), - Math.PI / 2 - Math.PI / 20);
                _position.x += plane.position.x;
                _position.y -= 6.1;
                _position.z -= 5;
    
                tree.position.set(_position.x, _position.y, _position.z);
    
                this._scene.add( tree );
                trees.push(tree);
            }
    
            this._trees.push(trees);
        }
    }
}

function createPlane(connection?: any[]) {
    // create a geometry
    let side = 200;
    const geometry = new PlaneGeometry(80, 40, side, 100);
    // const material = new MeshStandardMaterial({ color: '#271033'});
    let material = new THREE.MeshStandardMaterial({
        roughness: 1,
        color: new THREE.Color('#271033'),
        flatShading: true,
        side: THREE.DoubleSide,
    });
    
    const plane = new Mesh(geometry, material);
    
    plane.rotation.x = - Math.PI / 2 - Math.PI / 20;
    plane.position.y = - 6;
    plane.position.z = - 5;
    
    plane.castShadow = true;
    plane.receiveShadow = true;

    let pos = geometry.getAttribute("position");
    let pa = pos.array as any;
    const hVerts = geometry.parameters.heightSegments + 1;
    const wVerts = geometry.parameters.widthSegments + 1;

    let noise2D = createNoise2D();
    const ex = 1.1;
    for (let j = 0; j < hVerts; j++) {
        for (let i = 0; i < wVerts; i++) {
            if (connection) {
                pa[3 * (j * wVerts + i) + 2] = connection[3 * (j * wVerts + (wVerts - (i + 1))) + 2];
                continue;
            }
            pa[3 * (j * wVerts + i) + 2] =
            ( noise2D(i / 100, j / 100) + noise2D((i + 100) / 50, j / 50) * Math.pow(ex, 0) );
        }
    }

    plane.matrixAutoUpdate = true;
    pos.needsUpdate = true;

    // this method will be called once per frame
    // (plane as any).tick = (delta: number) => {
    //     plane.position.x -= 5 * delta;
    // }

    return plane;
}

function createPlane2(connection?: any[]) {
    // create a geometry
    let side = 200;
    const geometry = new PlaneGeometry(80, 40, side, 50);
    // const material = new MeshStandardMaterial({ color: '#271033'});
    let material = new THREE.MeshStandardMaterial({
        roughness: 1,
        color: new THREE.Color('#16142b'),
        flatShading: true,
    });
    
    const plane = new Mesh(geometry, material);

    plane.castShadow = true;
    plane.receiveShadow = true;

    plane.rotation.x = - Math.PI / 2 + Math.PI / 10;
    plane.position.y = - 6;
    plane.position.z = - 40;

    let pos = geometry.getAttribute("position");
    let pa = pos.array as any;
    const hVerts = geometry.parameters.heightSegments + 1;
    const wVerts = geometry.parameters.widthSegments + 1;

    let noise2D = createNoise2D();
    for (let j = 0; j < hVerts; j++) {
        for (let i = 0; i < wVerts; i++) {
            if (connection) {
                pa[3 * (j * wVerts + i) + 2] = connection[3 * (j * wVerts + (wVerts - (i + 1))) + 2];
                continue;
            }

            const ex = 1.1;
            pa[3 * (j * wVerts + i) + 2] =
            ( noise2D(i / 100, j / 100) + noise2D((i + 100) / 50, j / 50) * Math.pow(ex, 0) +
            noise2D((i + 400) / 25, j / 25) * Math.pow(ex, 2) +
            noise2D((i + 600) / 12.5, j / 12.5) * Math.pow(ex, 3) )
                +(noise2D((i + 800) / 6.25, j / 6.25) * Math.pow(ex, 4)) / 2;
        }
    }

    plane.matrixAutoUpdate = true;
    pos.needsUpdate = true;

    return plane;
}

async function loadTree() {
    const loader = new GLTFLoader();

    const downloadUrl = new URL('../assets/models/giant_low_poly_tree.glb', import.meta.url);
    const moonData = await loader.loadAsync(downloadUrl.href);

    // (moonData.scene as any).tick = () => {
    //     moonData.scene.rotation.y += 0.001;
    // };

    // const clip = moonData.animations[0];

    // const mixer = new AnimationMixer(moonData.scene.children[0]);
    // const action = mixer.clipAction(clip);
    // action.play();

    // (moonData.scene.children[0] as any).tick = (delta: number) => {
    //     mixer.update(delta);
    // }

    return moonData.scene.children[0];
}

function _getQBezierValue(t: number, p1: number, p2: number, p3: number) {
    var iT = 1 - t;
    return iT * iT * p1 + 2 * iT * t * p2 + t * t * p3;
}

function getQuadraticCurvePoint(startX: any, startY: any, cpX: any, cpY: any, endX: any, endY: any, position: any) {
    return {
        x:  _getQBezierValue(position, startX, cpX, endX),
        y:  _getQBezierValue(position, startY, cpY, endY)
    };
}