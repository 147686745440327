import React from "react";
import './three.scss';
import '../global.scss';
import { World, getWorldInstance } from "./threeWorld";

interface State {
}

let world: World;

export class ThreeScene extends React.Component<{}, State> {
    constructor(props: {}) {
        super(props);
    }

    render() {
        return (
            <div className="landscape" id="threeLandscape">
            </div>
        )
    }

    // After the component did mount, we set the state each second.
    async componentDidMount() {
        // Get a reference to the container element
        const container = document.getElementById('threeLandscape')!;
    
        // 1. Create an instance of the World app
        world = getWorldInstance(container);

        // complete async tasks
        await world.init()
        .catch((e) => {
            console.log(e)
        }).then(() => {
            // 2. Render the scene
            world.start(container);
        });
    }

    componentWillUnmount(): void {
        world.stop();
        const container = document.getElementById('threeLandscape');
        if (container && container.childNodes.length > 0) {
            container.removeChild(container.childNodes[0]);
        }
    }
}