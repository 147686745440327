import React from "react";
import './homepage.scss';
import * as THREE from 'three';
import { AmbientLight, Color, HemisphereLight, Mesh, PerspectiveCamera, PlaneGeometry, Scene, WebGLRenderer } from "three";
import { randInt } from "three/src/math/MathUtils";
import Typewriter from 'typewriter-effect';

interface State {
    isLoading: boolean
}

export class Homepage extends React.Component<{}, State> {
    world!: World;
    descriptorWords: string[] = ['Programmer', 'Fullstack Dev', 'Nap Enthusiast', 'Student', 'Game Designer', 'Typescript Shill'];

    constructor(props: {}) {
        super(props);
        this.state = {
            isLoading: true
        }
    }

    render(): React.ReactNode { 
        return (
            <div style={{
                width: '100%',
                height: '100%',
                position: 'absolute',
                top: 0,
                left: 0,
                backgroundColor: '#c4c4c4',
            }}>
                { this.state.isLoading ? 
                    // <ClimbingBoxLoader color="#FFFFFF" /> 
                    <div className="loading"/>
                        : 
                    <div className="info-text" id="info-text">
                        <div className="glitch" data-text="Jakob Brattke">Jakob Brattke</div>
                        <Typewriter
                            options={{
                                strings: this.descriptorWords,
                                autoStart: true,
                                loop: true,
                            }}
                        />
                    </div>
                }
                <div id="homepage" style={{ width: '100%', height: '100%'}} />
            </div>
        );
    }

    // After the component did mount, we set the state each second.
    async componentDidMount() {
        // Get a reference to the container element
        const container = document.getElementById('homepage')!;
    
        // 1. Create an instance of the World app
        this.world = new World(container);

        // complete async tasks
        await this.world.init()
        .catch((e) => {
            console.log(e)
        }).then(() => {
            // 2. Render the scene
            this.world.start();
            this.setState({ isLoading: false });
        });
    }

    componentWillUnmount(): void {
        this.world.stop();
        const container = document.getElementById('homepage');
        if (container && container.childNodes.length > 0) {
            for (let i = 0; i < container.childNodes.length; i++) {
                if (container.childNodes[i].nodeName === 'CANVAS') {
                    container.removeChild(container.childNodes[i]);
                }
            }
        }
    }
}

interface Mouse {
    x: number;
    y: number;
    prevX: number;
    prevY: number;
    vX: number;
    vY: number;
}

export class World {
    camera: THREE.PerspectiveCamera;
    scene: THREE.Scene;
    renderer: THREE.WebGLRenderer;
    loop: Loop;
    initialized: boolean = false;
    animationType: number = 0;
    animationInterval?: NodeJS.Timer;
    animationCounter: number = 0;
    animationIncrement: number = 0.01;
    pointArray: THREE.Mesh[] = [];
    vertLineArray: THREE.Line[] = [];
    horLineArray: THREE.Line[] = [];

    mouse: Mouse = {
        x: 0,
        y: 0,
        prevX: 0,
        prevY: 0,
        vX: 0,
        vY: 0,
    };

    plane: THREE.Mesh;

    // 1. Create an instance of the World app
    constructor(container: HTMLElement) {
        this.camera = createCamera();
        this.scene = createScene();
        this.renderer = createRenderer();
        this.loop = new Loop(this.camera, this.scene, this.renderer);

        const { light, ambientLight } = createLights();
        const plane = this.createPlane();
        this.plane = plane;
        this.scene.fog = new THREE.Fog( 0x121212, 0, 20 );

        this.loop.updatables.push(plane);
      
        this.scene.add(plane);
        this.scene.add(light, ambientLight);

        this.mouseEvents();
        this.onScroll();
        
        container.append(this.renderer.domElement);
        new Resizer(container, this.camera, this.renderer);
        window.addEventListener('resize', () => {new Resizer(container, this.camera, this.renderer)});
    }

    async init() {
        if (this.initialized) return;

        this.initialized = true;

        // asynchronous setup here
    }
    
    render() {
        this.renderer.render(this.scene, this.camera);
    }

    start(container?: HTMLElement) {
        if (container) {
            new Resizer(container, this.camera, this.renderer);
            container.append(this.renderer.domElement);
        }
        this.loop.start();
        this.rippleOutAnimation();
    }
    
    stop() {
        this.loop.stop();
    }

    hVerts: number = 100;
    wVerts: number = 100;
    hVertFactor: number = 10;
    wVertFactor: number = 10;

    createPlane() {
        const geometry = new PlaneGeometry(40, 20, this.wVerts, this.hVerts);
        let material = new THREE.MeshStandardMaterial({
            roughness: 1,
            color: new THREE.Color('#121212'),
            flatShading: true,
            side: THREE.DoubleSide,
        });
        
        const plane = new Mesh(geometry, material);
        
        plane.position.z = -6;
        
        plane.castShadow = true;
        plane.receiveShadow = true;

        let pos = geometry.getAttribute("position");
        let pa = pos.array as any;
        const hVerts = this.hVerts + 1;
        const wVerts = this.wVerts + 1;
        
        for (let j = 0; j < hVerts; j+=this.hVertFactor) {
            for (let i = 0; i < wVerts; i+=this.wVertFactor) {
                this.createBall(pa[3 * (j * wVerts + i)], pa[3 * (j * wVerts + i) + 1], -4);
            }
        }

        //draw lines
        let fog = false;
        for (let j = 0; j < hVerts; j+= this.hVertFactor) {
            let vertLinePoints: THREE.Vector3[] = [];
            for (let i = 0; i < wVerts; i+= this.wVertFactor) {
                vertLinePoints.push(new THREE.Vector3(pa[3 * (j * wVerts + i)], pa[3 * (j * wVerts + i) + 1], -4));
            }
            const vertLineMat = new THREE.LineBasicMaterial( { color: 0xFFFFFF, fog: fog } );
            const vertLineGeo = new THREE.BufferGeometry().setFromPoints( vertLinePoints );
            const vertLine = new THREE.Line( vertLineGeo, vertLineMat );
            vertLine.receiveShadow = false;
            vertLine.castShadow = false;
            this.scene.add( vertLine );
            this.vertLineArray.push(vertLine);
        }

        for (let i = 0; i < wVerts; i+= this.wVertFactor) {
            let horLinePoints: THREE.Vector3[] = [];
            for (let j = 0; j < hVerts; j+= this.hVertFactor) {
                horLinePoints.push(new THREE.Vector3(pa[3 * (j * wVerts + i)], pa[3 * (j * wVerts + i) + 1], -4));
            }
            const horLineMat = new THREE.LineBasicMaterial( { color: 0xFFFFFF, fog: fog } );
            const horLineGeo = new THREE.BufferGeometry().setFromPoints( horLinePoints );
            const horLine = new THREE.Line( horLineGeo, horLineMat );
            horLine.receiveShadow = false;
            horLine.castShadow = false;
            this.scene.add( horLine );
            this.horLineArray.push(horLine);
        }

        for (let j = 0; j < hVerts; j++) {
            for (let i = 0; i < wVerts; i++) {
                pa[3 * (j * wVerts + i) + 2] = Math.random() * 2;
            }
        }

        plane.matrixAutoUpdate = true;
        pos.needsUpdate = true;

        // this method will be called once per frame
        let time = 0;
        (plane as any).tick = (delta: number) => {
        }

        return plane;
    }

    createBall(x: number, y: number, z: number) {
        const geometry = new THREE.SphereGeometry( 0.2, 32, 32 );
        const material = new THREE.MeshStandardMaterial( {color: '#FFFFFF', fog: false} );
        const sphere = new THREE.Mesh( geometry, material );
        sphere.castShadow = false;
        sphere.receiveShadow = false;
        sphere.position.set(x, y, z);
        this.scene.add( sphere );
        this.pointArray.push(sphere);

        if (Math.random() > 0.25) {
            return;
        }
        const light = new THREE.PointLight( 0xFFFFFF, randInt(10, 100));
        light.position.set( x, y, z + 1 );
        this.scene.add( light );
    }

    mouseEvents() {
        let target = new THREE.Vector2();
        window.addEventListener('mousemove', (e) => {
            this.mouse.x = e.clientX - window.innerWidth / 2;
            this.mouse.y = e.clientY - window.innerHeight / 2;
            
            target.x = ( 1 - this.mouse.x ) * 0.0005;
            target.y = ( 1 - this.mouse.y ) * 0.0005;
            
            this.camera.rotation.x += 0.05 * ( target.y - this.camera.rotation.x );
            this.camera.rotation.y += 0.05 * ( target.x - this.camera.rotation.y );
        })
    }

    onScroll() {
        window.addEventListener('wheel', (e) => {
            if (e.deltaY > 0) {
                // document.getElementById('info-text')!.classList.add('scrollUp');
                this.sinWaveDownAnimation(1000, document.getElementById('info-text'));
                // setTimeout(() => {
                //     this.dropAnimation();
                // }, 500);
            } else {
                
            }
        })
    }

    rippleOutAnimation(duration?: number) {
        if (this.animationInterval) return;
        
        this.animationType = 0;
        this.animationIncrement = 0.05;
        const fps = 60;
        const interval = 1000 / fps;
        this.animationInterval = setInterval(() => {
            this.animationCounter += this.animationIncrement;
            for (let i = 0; i < this.pointArray.length; i++) {
                // calculate each as on a sin wave
                let x = this.pointArray[i].position.x;
                let y = this.pointArray[i].position.y;
                let z = this.pointArray[i].position.z;
                let amplitude = 0.2;
                let wavelength = 0.5;
                // let zOffset = Math.sin(x * wavelength + scrollPercent) * amplitude + Math.sin(y * wavelength + scrollPercent) * amplitude;
                // let zOffset = Math.sin(x * wavelength + this.animationCounter) * amplitude + Math.sin(y * wavelength + this.animationCounter) * amplitude;
                // let zOffset = Math.sin(y * wavelength + this.animationCounter) * amplitude;
                let zOffset = Math.sin(-Math.sqrt(x * x + y * y) * wavelength + this.animationCounter) * amplitude;
                this.pointArray[i].position.z = lerp(this.pointArray[i].position.z, zOffset - 4, 0.1);
            }
            this.updateLines();
        }, interval);

        if (!duration) return;
        setTimeout(() => {
            clearInterval(this.animationInterval);
            this.animationInterval = undefined;
        }, duration);
    }

    sinWaveDownAnimation(duration: number = 1000, element: HTMLElement | null = null) {
        if (this.animationType === 1) return;
        // can override ripple animation of type = 0
        if (this.animationInterval && this.animationType === 0) {
            clearInterval(this.animationInterval);
        }
        
        // element?.classList.add('scrollUp');
        this.animationType = 1;
        this.animationCounter = 0.5;
        this.animationIncrement = 0.1;
        const fps = 60;
        const interval = 1000 / fps;
        this.animationInterval = setInterval(() => {
            this.animationCounter += this.animationIncrement;
            // this.plane.position.y = lerp(this.plane.position.y, this.plane.position.y + 3 , 0.1);
            for (let i = 0; i < this.pointArray.length; i++) {
                // calculate each as on a sin wave
                let x = this.pointArray[i].position.x;
                let y = this.pointArray[i].position.y;
                let z = this.pointArray[i].position.z;
                let amplitude = 2.5;
                let wavelength = 0.4;
                let zOffset = Math.sin(y * wavelength + this.animationCounter) * amplitude;
                this.pointArray[i].position.z = lerp(this.pointArray[i].position.z, zOffset - 4, 0.1);
            }
            this.updateLines();
        }, interval);

        setTimeout(() => {
            clearInterval(this.animationInterval);
            this.animationInterval = undefined;
            // element?.classList.remove('scrollUp');
            // element?.classList.add('hide');
            this.rippleOutAnimation();
            // this.dropAnimation();
        }, duration);
    }

    dropAnimation(duration: number = 1000) {
        if (this.animationType === 2) return;
        // can override ripple animation of type = 0
        if (this.animationInterval && this.animationType === 0) {
            clearInterval(this.animationInterval);
        }
        
        // this.animationType = 2;
        // this.animationCounter = 0;
        // this.animationIncrement = 0.1;
        const fps = 60;
        const interval = 1000 / fps;
        const intervalID = setInterval(() => {
            // this.animationCounter += this.animationIncrement;
            // drop the bottom row of points and have the camera follow
            const yChange = 0.025;
            for (let i = 0; i < this.pointArray.length; i++) {
                // if last row
                if (i >= this.pointArray.length - this.hVertFactor - 1) {
                    this.pointArray[i].position.y -= yChange;
                }
            }
            this.camera.position.y -= yChange;
            this.updateLines();
        }, interval);

        setTimeout(() => {
            clearInterval(intervalID);
            // this.animationInterval = undefined;
        }, duration);
    }

    updateLines() {
        for (let i = 0; i < this.pointArray.length; i++) {
            let factor = this.hVertFactor + 1;
            if (i % factor === 0) {
                this.vertLineArray[i / factor].geometry.setFromPoints(
                    this.pointArray.slice(i, i + factor).map(p => p.position)
                );
                this.horLineArray[i / factor].geometry.setFromPoints(
                    this.pointArray.filter((p, index) => index % factor === i / factor).map(p => p.position)
                );
            }
        }
    }
}

class Loop {
    camera: THREE.PerspectiveCamera;
    scene: THREE.Scene;
    renderer: THREE.WebGLRenderer;
    updatables: any[];
    private clock: THREE.Clock = new THREE.Clock();
    
    constructor(camera: THREE.PerspectiveCamera, scene: THREE.Scene, renderer: THREE.WebGLRenderer) {
      this.camera = camera;
      this.scene = scene;
      this.renderer = renderer;
      this.updatables = [];
    }
  
    start() {
        this.renderer.setAnimationLoop(() => {
            // tell every animated object to tick forward one frame
            this.tick();

            // render a frame
            this.renderer.render(this.scene, this.camera);
        });
    }
  
    stop() {
        this.renderer.setAnimationLoop(null);
    }

    // Code to update animations will go here
    tick() {
        const delta = this.clock.getDelta();

        for (const object of this.updatables) {
            object.tick(delta);
        }
    }
}

class Resizer {
    constructor(container: HTMLElement, camera: THREE.PerspectiveCamera, renderer: THREE.WebGLRenderer) {
        // Set the camera's aspect ratio
        camera.aspect = container.clientWidth / container.clientHeight;

        // update the camera's frustum
        camera.updateProjectionMatrix();
    
        // update the size of the renderer AND the canvas
        renderer.setSize(container.clientWidth, container.clientHeight);
    
        // set the pixel ratio (for mobile devices)
        renderer.setPixelRatio(window.devicePixelRatio);
    }
}

function createLights() {
    // Create a directional light
    const light = new HemisphereLight('white', 5, 1);
    
    const ambientLight = new AmbientLight('white', 0);

    // move the light right, up, and towards us
    light.position.set(0, 10, 10);

    return {light, ambientLight};
}

function createRenderer() {
    const renderer = new WebGLRenderer({ antialias: true });

    // turn on the physically correct lighting model
    renderer.physicallyCorrectLights = true;
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
  
    return renderer;
}

function createScene() {
    const scene = new Scene();
  
    scene.background = new Color('#141414');
  
    return scene;
}

function createCamera() {
    const camera = new PerspectiveCamera(
      70, // fov = Field Of View
      1, // aspect ratio (dummy value)
      0.1, // near clipping plane
      100, // far clipping plane
    );
  
    // move the camera back so we can view the scene
    camera.position.set(0, 0, 10);
    camera.zoom = 1;
  
    return camera;
}

function lerp(x: number, y: number, a: number): number {
    return (1 - a) * x + a * y
}