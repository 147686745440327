import * as React from "react";
import './sidebar.scss';
import '../global.scss';
import { faHouse } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useLocation } from 'react-router-dom';

interface Props {
  isVisible: boolean;
}

const divStyle = (props: Props): React.CSSProperties => ({
  width: props.isVisible ? "23rem" : "0rem",
});

export const SidebarComponent: React.FC<Props> = (props: any) => (
  <div 
    id="mySidenav" 
    className="sidenav pt-3" 
    style={divStyle(props)}
  >
    <Link to="/" style={{ textDecoration : 'none', float: 'left', color: '#723D46' }}>
      <FontAwesomeIcon 
        style={{ fontSize : (useLocation().pathname !== "/" && props.isVisible) ? "2rem" : "0" }} 
        icon={faHouse} 
        className="home-icon mx-3 my-2"
       />
    </Link>

    {/* <Link to="/projects" style={{ textDecoration : 'none' }}>
      <h3 className="border-bottom mt-4 top-item pt-5">Projects</h3>
    </Link>
    <Link to="/about" style={{ textDecoration : 'none' }}>
      <h3 className="border-bottom">About Me</h3>
    </Link>
    <Link to="/contact" style={{ textDecoration : 'none' }}>
      <h3 className="bottom-item pb-5">Contact/Links</h3>
    </Link> */}
    <Link to="/" style={{ textDecoration : 'none' }}>
      <h3 className="border-bottom mt-4 top-item pt-5">In the works!</h3>
    </Link>
  </div>
);