import '../global.scss';
import '../App.scss';

export const ContactComponent: React.FC<{}> = () => (
    <header className="App-header">
        <p className="m-0">Links</p>
        <div className="row mb-2" style={{width: '400px', borderBottom: '1px solid white'}}>
            <div className="col">
                <a href="https://www.linkedin.com/in/brattke-j/" target="_blank" rel="noreferrer" className="link">linkedin.com/in/brattke-j</a>
            </div>
            <div className="col">
                <a href="https://github.com/jbrattke" target="_blank" rel="noreferrer" className="link">github.com/jbrattke</a>
            </div>
        </div>
        <p className="mb-1">Contact</p>
        <div className="d-flex">
            <p style={{fontSize: '0.5em'}}>
                Email me at 
            </p>
            <a href="mailto: me@jbrattke.com" target="_blank" rel="noreferrer" className="link mx-2">me@jbrattke.com</a>
            <p style={{fontSize: '0.5em'}}>or</p> 
            <a href="mailto: jbrattke@gmail.com" target="_blank" rel="noreferrer" className="link mx-2">jbrattke@gmail.com</a>
        </div>
    </header>
);